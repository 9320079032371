<template>
  <div class="device-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-form class="geology-form">
            <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              size="medium"
              @click="onAdd"
              >新增</el-button
            >
          </el-form>
        </div>
        <div class="action-right"></div>
      </div>
    </div>

    <el-table
      class="list-table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      style="width: 100%"
      :stripe="true"
      :border="false"
      v-loading="tableLoading"
    >
      <el-table-column label="编号" prop="Id"></el-table-column>
      <el-table-column label="编码" prop="EnCode"></el-table-column>
      <el-table-column label="名称" prop="FullName"></el-table-column>
      <el-table-column label="排序" prop="SortCode"></el-table-column>
      <el-table-column label="创建时间" prop="CreateTime" width="180">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-button
            type="warning"
            icon="el-icon-error"
            size="mini"
            style="margin-left: 10px"
            @click="onListDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="formtype == 'add' ? '添加字典分类' : '修改字典分类'"
      :visible.sync="dialogFormVisible"
      width="550px"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
      :destroy-on-close="true"
    >
      <el-form
        ref="form"
        class="geology-form"
        :rules="formRules"
        :model="form"
        label-width="120px"
        style="padding-top: 20px"
      >
        <el-row>
          <el-col :span="20" :offset="1">
            <el-form-item label="分类编码" prop="EnCode">
              <el-input v-model="form.EnCode" size="small"></el-input>
            </el-form-item>
            <el-form-item label="分类名称" prop="FullName">
              <el-input v-model="form.FullName" size="small"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="SortCode">
              <el-input v-model="form.SortCode" size="small"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit"
                >立即保存</el-button
              >
              <el-button
                type="primary"
                plain
                size="medium"
                style="margin-left: 10px"
                @click="dialogFormVisible=false"
                >取消</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDictypeList,
  delDictype,
  addDictype,
  editDictype,
} from "@/api/system";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      dialogFormVisible: false,

      formtype: "add",
      form: {},
      formRules: {
        EnCode: [
          { required: true, trigger: "blur", message: "请输入分类编码" },
        ],
        FullName: [
          { required: true, trigger: "blur", message: "请输入分类名称" },
        ],
        SortCode: [
          { required: true, trigger: "blur", message: "请输入分类排序" },
        ],
      },
    };
  },
  components: {},
  methods: {
    getListData() {
      this.tableLoading = true;
      getDictypeList().then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delDictype(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => {});
    },
    onAdd() {
      this.form = {
          SortCode:100
      };
      this.formtype = "add";
      this.dialogFormVisible = true;
    },
    onEdit(item) {
      this.form = item;
      this.formtype = "edit";
      this.dialogFormVisible = true;
    },
    onFormClose() {
      this.getListData();
      this.dialogFormVisible = false;
    },

    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formtype != "edit") {
            addDictype(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.getListData();
                  this.dialogFormVisible = false;
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editDictype(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.getListData();
                  this.dialogFormVisible = false;
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.getListData();
  },
};
</script>